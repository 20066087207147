<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="产品名称">
					<c-input name="product_name"></c-input>
				</m-search-item>
<!--				<m-search-item label="产品编码">
					<c-input name="product_code"></c-input>
				</m-search-item>-->
				<m-search-item label="产品颜色">
					<c-input name="product_spec"></c-input>
				</m-search-item>
				<m-search-item label="生产说明">
					<c-input name="product_remark"></c-input>
				</m-search-item>
				<m-search-item label="产品类目">
					<c-select name="product_type_id" options="PRRODUCT_CLASSIFY"></c-select>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button v-if="auth('b_product_edit')" color="sys" @click="createProduct">新建产品</c-button>
			</m-operate>

			<c-table ref="table" height="grow">
				<c-table-column
					label="产品编码"
					width="120"
					name="product_code"
				></c-table-column>
				
				<c-table-column
					label="产品图片/名称"
					width="220"
					name="product_img"
				>
					<template #default="{data}">
					    <div class="flex-center-cross">
					        <c-file v-if="data.product_img" media :src="data.product_img" style="margin-right: 18px;width:40px;height:40px;"></c-file>
							<span>{{data.product_name}}</span>
					    </div>
					</template>
				</c-table-column>
				
				<c-table-column
					label="颜色"
					width="100"
					name="product_spec"
				></c-table-column>

				<c-table-column
					label="单位"
					width="50"
					name="unit"
				></c-table-column>
				
				<c-table-column
					label="工艺路线"
					width="120"
					name="route_name"
				></c-table-column>
				
				<c-table-column
					label="生产参数"
					width="220"
					name="product_json"
				>
					<template #default="{data}">
						<template v-if="data.product_json" >
							<div v-for="(item, i) in JSON.parse(data.product_json)" :key="i">{{item.name}}：{{item.value}}</div>
						</template>
					</template>
				</c-table-column>
				
				<c-table-column
					label="生产说明"
					width="200"
					name="product_remark"
				></c-table-column>

				<c-table-column
					label="所属类目"
					width="70"
					name="product_type_name"
				></c-table-column>

				<c-table-column
					label="物料/组件种类"
					width="100"
					name="material_kinds"
				>
					<template v-slot="{data}">
						<span v-if="data.material_kinds == 0" style="color:#999">0</span>
						<a v-else style="text-decoration: underline;color: #003dd9" @click="bomConfig(data)">
							<span>{{data.material_kinds}}</span>
						</a>
					</template>
				</c-table-column>

				<c-table-column
					label="关联客户数"
					width="80"
					name="customer_count"
				></c-table-column>
				
				<c-table-column
					label="创建人"
					width="100"
					name="create_user_realname"
				></c-table-column>
				
				<c-table-column
					label="创建时间"
					width="140"
					name="create_date"
				></c-table-column>

				<c-table-column
					type="button"
					label="操作"
					width="190"
				>
					<template v-slot="{data}">
						<c-table-button v-if="auth('b_product_edit')" @click="editProduct(data)">编辑</c-table-button>
						<c-table-button v-if="auth('b_product_edit')" @click="deleteProduct(data)">删除</c-table-button>
						<c-table-button v-if="auth('b_product_bom')" @click="bomConfig(data)">产品组件配置</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>

		<!--新增、编辑 弹框-->
		<c-dialog ref="productEdit" :title="editState ? '编辑产品' : '新建产品'" width="900" height="600" @resolve="submitProduct">
			<c-form ref="productForm" unit-width="100%">
				<c-form-item label="产品名称" required>
					<c-input name="product_name" maxlength="200"></c-input>
				</c-form-item>
				<c-form-item label="产品编码" style="width:50%">
					<c-input name="product_code" maxlength="50" :disabled="editState"></c-input>
					<template #tip>
						<span style="color:red">物料编码不填写则自动生成，生成后不支持更改</span>
					</template>
				</c-form-item>
				<c-form-item label="颜色" required style="width:50%;">
					<c-input name="product_spec" maxlength="50" :disabled="editState"></c-input>
					<template #tip>
						<span style="color:red">填写后不支持更改</span>
					</template>
				</c-form-item>
				<c-form-item label="工艺路线" required style="width:50%">
					<c-select name="route_id" options="PROCESSES_ROUTE"></c-select>
				</c-form-item>
				<c-form-item label="单位" style="width:200px;">
					<c-input name="unit" maxlength="5" value="个"></c-input>
				</c-form-item>
				<c-form-item label="图片">
				    <c-upload-file name="product_img" media type="image" tip="请上传产品图片"></c-upload-file>
				</c-form-item>
				<c-form-item label="生产参数" >
					<div class="flex-start" style="padding-bottom: 1px">
						<c-button @click="addJson">新增一行</c-button>
					</div>
				    <c-table ref="jsonTable" :data="jsonTableData" :paging="false" border>
				    	<c-table-column
				    		label="参数分类"
				    		name="type"
				    		width="100"
				    		required
				    	>
				    		<template #default="{data}">
				    			<c-select name="type" v-model="data.type" options="DIC_TYPE_LIST" style="height:25px;" @change="typeChangeOption($event, data)"></c-select>
				    		</template>
				    	</c-table-column>
				    	<c-table-column
				    		label="参数名"
				    		name="name"
				    		width="100"
				    		required
				    	>
				    		<template #default="{data}">
				    			<c-input v-model="data.name" maxlength="12"></c-input>
				    		</template>
				    	</c-table-column>
				    	<c-table-column
				    		label="可选参数值"
				    		name="value"
				    		width="400"
				    		required
				    	>
				    		<template #default="{data}">
								<c-input v-if="data.type == -1" v-model="data.text_value" name="value" type="textarea" placeholder="多个选项请使用英文逗号分隔" maxlength="1000"></c-input>
								<c-select v-else name="value" v-model="data.value" :options="dic_list[data.type]" multiple search></c-select>
				    		</template>
				    	</c-table-column>
						<c-table-column
							label="类型"
							name="type"
							width="80"
							required
						>
							<template #default="{data}">
								<c-select name="select_type" v-model="data.select_type" :options="selectTypeOptions" style="height:25px;"></c-select>
							</template>
						</c-table-column>
				    	<!-- 操作 -->
				    	<c-table-column
				    		type="button"
				    		label="操作"
				    		width="60"
				    	>
				    		<template #default="{data}">
				    			<c-table-button @click="cancelJson(data)">取消</c-table-button>
				    		</template>
				    	</c-table-column>
				    </c-table>
				</c-form-item>
				<c-form-item label="生产说明" >
				    <c-input name="product_remark" type="textarea" maxlength="1000"></c-input>
				</c-form-item>
				<c-form-item label="所属类目" required>
					<c-select name="product_type_id" options="PRRODUCT_CLASSIFY" :disabled="editState"></c-select>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
		</c-dialog>
		<!--笔记BOM信息弹窗-->
		<c-dialog ref="bomDialog" title="产品组件配置" width="900" height="600" @resolve="submitProductBom" :button="this.bom_edit_flag">
			<c-form ref="productBomForm" unit-width="100%">
				<c-form-item label="商品编码" width="400">
					<c-input name="product_code" disabled></c-input>
				</c-form-item>
				<c-form-item label="商品名称" width="400">
					<c-input name="product_name" disabled></c-input>
				</c-form-item>
				<c-form-item label="商品基数" width="300" required>
					<c-input name="product_count" type="number" decimal="5" range="0.00001,999999" :disabled="!this.bom_edit_flag"></c-input>
				</c-form-item>
				<c-form-item label="组件/物料">
					<div class="flex-start" style="padding-bottom: 1px">
						<c-button @click="selectMaterial">新增组件/物料</c-button>
					</div>
				    <c-table ref="materialTable" :data="materialData" :paging="false" height="350" border>
				    	<c-table-column
				    		label="组件/物料编码"
				    		name="material_code"
				    		width="100"
				    	>
				    	</c-table-column>

				    	<c-table-column
				    		label="组件/物料编码"
				    		name="material_name"
				    		width="120"
				    	>
				    	</c-table-column>

				    	<c-table-column
				    		label="单位"
				    		name="material_unit"
				    		width="50"
				    	>
				    	</c-table-column>

				    	<c-table-column
				    		label="数量"
				    		name="material_count"
				    		width="80"
				    		required
				    	>
				    		<template #default="{data}">
							    <c-input name="material_count" type="number" decimal="5" range="0.00001,999999"  width="80" v-model="data.material_count"></c-input>
				    		</template>
				    	</c-table-column>
				    	<c-table-column
				    		label="备注"
				    		name="remark"
				    		width="120"
				    	>
				    		<template #default="{data}">
							    <c-input name="remark" maxlength="50" width="80" v-model="data.remark"></c-input>
				    		</template>
				    	</c-table-column>
					    <c-table-column
						    type="button"
						    label="操作"
						    width="50"
					    >
						    <template v-slot="{data}">
							    <c-table-button @click="removeMaterial(data)">删除</c-table-button>
						    </template>
					    </c-table-column>
				    </c-table>
				</c-form-item>
			</c-form>
		</c-dialog>
		<!--物料选择弹框-->
		<material-selector ref="materialSelector" multiple @resolve="handleMaterialSelect"></material-selector>
  </page>
</template>

<script>
import materialSelector from '@/components/service/bom_material_selector.vue'
import {mapState} from 'vuex'
export default {
	name: 'm_product',
	components: {
		materialSelector
	},
	data() {
		return {
		  editState: false,
		  jsonTableData: [],
		  selectTypeOptions:[
			  {
			  	name: '单选',
			  	value: 1
			  }, {
			  	name: '多选',
			  	value: 2
			  }
		  ],
		  dic_list: {},
		  materialData: [],//组件物料数据
		  product_id:0,
		  bom_edit_flag:false //是否可以编辑BOM
		}
	},
	computed: {
		...mapState(['userInfo'])
	},
	mounted() {
		this.$refs.search.search();
	},
	methods: {
		//搜索
		searchHandle(data) {
			data.type = 1;
			this.$refs.table.load({
				url: '/product/list',
				data
			});
		},
		//新建产品
		createProduct() {
			this.jsonTableData = [];
			this.editState = false;
			this.$refs.productForm.clear();
			this.$refs.productForm.set({"product_type_id": 1,"unit":"个","route_id":0});
			this.$refs.productEdit.open();
		},
		//编辑产品
		editProduct(val) {
			this.jsonTableData = [];
			this.editState = true;
			this.$refs.productForm.clear();
			this.$refs.productForm.set(val);
			//初始化对象
			if(val.product_json){
				this.jsonTableData = JSON.parse(val.product_json)
			}
			
			this.jsonTableData.forEach(item => {
				if(!item.select_type){
					item.select_type = 1;
				}
				this.typeChangeOption({
					name: item.name,
					value: item.type
				},item)
			});
			
			this.$refs.productEdit.open();
		},
		//删除产品
		deleteProduct(val){
			this.$confirm({
				title: '确认',
				message: '确定要删除【'+val.product_name+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/product/delete',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$message({
							  message: '删除成功',
							  type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		// 添加生产参数行
		addJson() {
			this.jsonTableData.push({
				name: '',
				type: '',
				value: '',
				select_type: 1
			});
		},
		//取消生产属参数行
		cancelJson(rowData) {
			const index = this.jsonTableData.indexOf(rowData);
			this.jsonTableData.splice(index, 1);
		},
		//提交保存
		submitProduct(stop) {
			stop();
			this.$refs.productForm.submit({
				url: this.editState ? '/product/edit' : '/product/add',
				rule: {
					product_name: '请填写产品名称',
					// product_code: '请填写产品编码',
					product_spec: '请填写产品颜色',
					product_type_id: '请选择产品所属类目',
					route_id: '请选择工艺路线',
					product_json: [{
						type: 'function',  // 自定义函数校验
						fun: (value, data) => {
							if(this.jsonTableData.length > 0){
								var flag = true;
								this.jsonTableData.forEach(item => {
									if(!item.type){
										flag = false;
									//自定义
									}else if(item.type == -1){
										if(!item.name || !item.type || !item.text_value ){
										 	flag = false;
										}
									}else{
										if(!item.name || !item.type || !item.value || item.value.length ==0){
											flag = false;
										}
									}
								});
								return flag;
							}else{
								return true;
							}
						},
						message: '请完善生产参数信息'
					}],
					product_json2: [{
						type: 'function',  // 自定义函数校验
						fun: (value, data) => {
							if(this.jsonTableData.length > 0){
								var flag = true;
								this.jsonTableData.forEach(item => {
									var count = 0;
									this.jsonTableData.forEach(item2 => {
										if(item.name == item2.name){
											count ++;
										}
									});
									if(count > 1){
										flag = false;
									}
								});
								return flag;
							}else{
								return true;
							}
						},
						message: '存在重复的生产参数名'
					}]
				},
				dataFormatter: data => {
					data.type = 1;
					this.jsonTableData.forEach(item => {
						if(item.type == -1){
							item.text_value = item.text_value.replace(/\r\n|\r|\n/g, '').replace(/^,/, '').replace(/,$/, '');
							item.value = item.text_value;
						}
					});
					data.product_json = JSON.stringify(this.jsonTableData);
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.productEdit.close();
				}
			});
		},
		//分类选择事件
		typeChangeOption(data,row){
			var type_id = data.value;
			var type_name = data.name;
			//字典库
			if(type_id !=-1){
				row.name = type_name;
				if(!this.dic_list[type_id]){
					this.request({
						url: '/basic/dic/info_list',
						data: {
							dic_type_id: type_id
						},
						success: data => {
							if(data){
								this.$set(this.dic_list,type_id,
									Array.from(data, item => {
										return {
											name: item.value,
											value: item.value
										}
									})
								)
							}
						}
					});
				}
			}
		},
		//配置BOM数据
		bomConfig(data){
			if(this.auth('b_product_bom')){
				this.bom_edit_flag = true;
			}else {
				this.bom_edit_flag = false;
			}
			this.product_id = data.id;
			this.materialData = [];
			this.$refs.productBomForm.clear();
			this.request({
				url: '/bom/detail',
				data: {
					product_id: data.id
				},
				success: data => {
					if(data && data.product_info){
						this.$refs.productBomForm.set(data.product_info);
					}
					if(data && data.material_list){
						this.materialData = data.material_list
					}
					this.$refs.bomDialog.open();
				}
			});
		},
		//选择物料
		selectMaterial(){
			var material_ids = [];
			if (this.materialData && this.materialData.length > 0){
				this.materialData.forEach(item => {
					console.log(item);
					material_ids.push(item.material_id);
				});
			}
			this.$refs.materialSelector.open(this.product_id||0,material_ids);
		},
		//删除物料
		removeMaterial(data){
			// 删除指定元素
			let index = this.materialData.indexOf(data);
			if (index !== -1) {
				this.materialData.splice(index, 1);
			}
		},
		//确认选择物料
		handleMaterialSelect(rows){
			if (rows){
				rows.forEach(item => {
					var material = {};
					material.material_id = item.id;
					material.material_code = item.product_code;
					material.material_name = item.product_name;
					material.material_unit = item.unit;
					this.materialData.push(material);
				});
			}
		},
		//提交BOM数据
		submitProductBom(stop){
			stop();
			this.$refs.productBomForm.submit({
				url: '/bom/edit',
				rule: {
					product_count: '请填写商品基数',
					materialData: [{
						type: 'function',  // 自定义函数校验
						fun: (value, data) => {
							if(this.materialData.length > 0){
								var flag = true;
								this.materialData.forEach(item => {
									if(!item.material_count || parseFloat(item.material_count) <= 0){
										flag = false;
									}
								});
								return flag;
							}else{
								return false;
							}
						},
						message: '请正确填写组件/物料信息'
					}]
				},
				dataFormatter: data => {
					data.product_id = this.product_id;
					data.material_list = this.materialData;
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();//刷新列表
					this.$refs.bomDialog.close();//关闭弹窗
				}
			});
		}
  }
}
</script>